import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Subtitle from "../Subtitle";
import Button from "../Button";
import SingleBlog from "../SingleBlog";
import 'swiper/swiper.min.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import NextBtn from "../NextBtn";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-160 insight-slider '}>
            <div className="insight-area">
                <Container>
                    <Row>
                        <Col lg={5} md={5}>
                            <div className="insight-area__left">
                                <Subtitle text={'Related <span>articles</span>'}/>
                            </div>
                        </Col>
                        <Col lg={7} md={7}>
                            <div className="insight-area__right d-flex ">
                                <Button src={'/media-center'} hoverBorderColor={'#CA0034'} hoverBackground={'#CA0034'}
                                        border={'1px solid #2B3944'} color={'#2B3944'} background={'transparent'}
                                        text={'View All'}/>
                                <div className="navigation">
                                    <NextBtn nextId={'next_insight'} prevId={'prev_insight'}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'blog-wrap desktop'}>
                        {
                            data?.map((item) => {
                                return (
                                    <Col md={4}>
                                        <SingleBlog slug={item?.page_data?.slug} date={item?.page_data?.short_desc} category={item?.page_data?.type} title={item?.page_data?.subtitle}
                                                    img={item?.images?.list?.[0]?.full_path}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                    <Row className={'blog-wrap mobile'}>
                        <Col>
                            {
                                data &&
                                <Swiper
                                    // install Swiper modules
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    speed={900}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1
                                            ,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    navigation={{
                                        prevEl: "#prev_insight",
                                        nextEl: "#next_insight",
                                        disabledClass: "swiper-button-disabled"
                                    }}
                                    modules={[Navigation]}
                                >
                                    {
                                        data?.length > 0 && data?.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <SingleBlog
                                                        slug={item?.page_data?.slug}
                                                        date={item?.page_data?.short_desc}
                                                        category={item?.page_data?.type}
                                                        title={item?.page_data?.subtitle}
                                                        img={item?.images?.list?.[0]?.full_path}
                                                    />
                                                </SwiperSlide>
                                            );
                                        })
                                    }

                                </Swiper>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100%;

    .blog-wrap {
        padding-top: 60px;

    }

    .navigation {
        display: none;
    }

    .mobile {
        display: none;
        height: 100%;
    }

    .desktop {
        display: block;
        display: flex;
        height: 100%;
    }

    .insight-area__right {
        justify-content: end;
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        .insight-area__right {
            justify-content: end;
            padding-top: unset;
        }

        .dc-btn {
            display: none;
        }

        .navigation {
            display: block;
            display: flex;
        }
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        .insight-area__right {
            justify-content: space-between;
            padding-top: 40px;
        }

        .mobile {
            display: block;
            height: 100%;
        }

        .desktop {
            display: none;
            height: 100%;
        }

        .navigation {
            display: block;
            display: flex;
        }
    }
`;

export default React.memo(MyComponent);
