import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../ImgP";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import NextBtn from "../NextBtn";

const Banner = ({offset, bannerData}) => {
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);

    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.8;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }
    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };

    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };

    // handle pagination
    var pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const handleSlideChange = (event) => {
        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }
    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };

    // data fetch
    const data = bannerData?.posts?.list;

    return (
        <StyledBanner offset={offset} className={'home-banner'}>
            <Swiper
                ref={swiperRef}
                spaceBetween={0}
                speed={2000}
                onSlideChange={handleSlideChange}
                onProgress={handleProgress}
                touchStart={handleTouchStart}
                onSetTransition={handleSetTransition}
                grabCursor={false}
                watchSlidesProgress={true}
                mousewheelControl={true}
                keyboardControl={true}
                autoplay={true}
                navigation={{
                    prevEl: '#banner-prev',
                    nextEl: '#banner-next',
                }}
                pagination={pagination}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper main-swiper"
            >
                {
                    data && data?.length > 0 && data?.map((item, index) => {
                        const filteredDesktop = item?.images?.filter(item => item.desktop === 'on')
                        const filteredMobile = item?.images?.filter(item => item.mobile === 'on')
                        return (
                            <SwiperSlide key={index}>
                                <div className="slide-inner">
                                    {
                                        window.innerWidth > 767 ?
                                            <div className="desktop">
                                                <Img
                                                    alt={item?.images?.[0]?.short_title}
                                                    src={filteredDesktop[0]?.full_path ? filteredDesktop[0]?.full_path : '/images/dynamic/banner.jpg'}/>
                                            </div>

                                            :

                                            <div className="mobile">
                                                <Img
                                                    alt={item?.images?.[0]?.short_title}
                                                    src={filteredMobile[0]?.full_path ? filteredMobile[0]?.full_path : '/images/dynamic/mobile-banner.jpg'}/>
                                            </div>

                                    }
                                    <Container>
                                        <Row>
                                            <Col>
                                                <div className="slide-inner__info">
                                                    {
                                                        item?.data?.title && index === 0 ?
                                                            <h1 data-lag={'0.2'}
                                                                className={'swiper__title split-up-titlee'}>{ReactHtmlParser(item?.data?.title)}</h1> :
                                                            <h3 data-lag={'0.2'}
                                                                className={'swiper__title split-up-titlee'}>{ReactHtmlParser(item?.data?.title)}</h3>
                                                    }
                                                    {/*h1 for first slide*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

            <div className="swipper-navigation-slider-custom">
                <Swiper
                    ref={swiperRefTwo}
                    speed={2000}
                    slidesPerView={data && data.length > 3 ? 4 : 3}
                    spaceBetween={30}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 30,

                        },
                        375: {
                            slidesPerView: 1,
                            spaceBetween: 30,

                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 30,

                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiperNext"
                >
                    {
                        data?.map((e, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div className={`slide-inner-item ${activeSlideIndex === i ? 'active' : ''}`}
                                         onClick={() => handleButtonClickPagination(i)}>
                                        <span>{e?.data?.subtitle}</span>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>

            {/*next prev navigation*/}
            <div className="navigation">
                <NextBtn prevId={'banner-prev'} nextId={'banner-next'} borderColor={'#fff'}
                         iconColor={'#fff'}/>
            </div>

            {/*feature box*/}
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
    height: 100vh;
    position: relative;

    .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
        display: none;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-disabled {
        opacity: 0.5 !important;
    }
    
    .global-image {
        transition: clip-path .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clip-path .8s cubic-bezier(.29, .73, .45, 1);
        will-change: clip-path;
        overflow: hidden;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .swiper-slide-active, .swiper-slide-visible {
        .global-image {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        .slide-inner {
            &__info {
                transition-delay: 0.3s;
                opacity: 1;
                transform: translateX(0px) !important;

                .dc-btn {
                    transition-delay: 0.4s;
                    opacity: 1;
                    transform: translateX(0px) !important;
                }
            }

        }
    }

    //custom navigation
    .swipper-navigation-slider-custom {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        min-height: 75px;
        text-align: left;
        margin: 0 auto;
        padding-left: ${props => props.offset ? props.offset : '90'}px;
        padding-right: ${props => props.offset ? props.offset : '90'}px;
        justify-content: space-between;
        gap: 15px;
        flex-direction: row;

        .slide-inner-item {
            width: 100%;
            position: relative;
            background: transparent !important;
            opacity: 1 !important;
            padding: 20px 0px 0;
            cursor: pointer;

            @media (min-width: 1536px) {
                padding-right: 15px;
            }

            span {
                font-size: 20px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 28px;
                opacity: 1 !important;
                transition: 0.7s all ease;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 4px;
                background: #CA0034;
                width: 0;
                transition: 1s all cubic-bezier(.25, .74, .22, .99);
                opacity: 0;
            }

            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                background: transparent !important;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 99;
            }

            &.active {
                &:after {
                    width: 100%;
                    opacity: 1;
                }

            }

        }
    }

    //feature box
    .feature-box-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -110px;
        z-index: 999;
        width: calc(100% - 30px);
        margin: 0 auto;

        .col-md-3 {
            padding: 0;
        }

        .single-item-wrapper {
            background: white;
            border-right: 1px solid rgba(198, 198, 198, 0.5);

            &.last {
                background: #CA0034;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: none !important;

                a {
                    width: 100%;
                    text-align: left;

                    .content {
                        align-items: center;
                        justify-content: center;
                    }

                    svg {
                        #Ellipse_601 {
                            transition: 1s all cubic-bezier(.25, .74, .22, .99);

                        }
                    }

                    &:hover {
                        svg {
                            #Ellipse_601 {
                                opacity: 1;
                                stroke-dasharray: 520px;
                            }
                        }
                    }
                }
            }

            a {
                padding: 40px 40px 45px;
                display: block;
                height: 100%;

                .content {
                    z-index: 4;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    p {
                        color: #C6C6C6;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        margin-bottom: 40px;
                    }

                    h3, h1 {
                        margin: 0 0 0px;
                        color: #3C3C3B;
                        font-family: "Avenir Heavy";
                        font-size: 28px;
                        letter-spacing: -1px;
                        line-height: 36px;
                        transition: 1s all cubic-bezier(.25, .74, .22, .99);
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }
                }

                svg {
                    #Rectangle_5661 {
                        transition: 1s all cubic-bezier(.25, .74, .22, .99);
                    }
                }

                &:hover {
                    h3 {
                        color: ${hover};
                    }

                    svg {
                        #Rectangle_5661 {
                            opacity: 1;
                            stroke-dasharray: 140px;
                        }
                    }
                }
            }
        }
    }

    .slide-inner {
        position: relative;
        height: 100dvh;

        .container {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50%);
            transform: translateY(-50%);
            z-index: 2;
        }

        .global-image {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            }
        }

        &__info {
            position: relative;
            overflow: hidden;
            transform: translateX(50%);
            transition: all 2s cubic-bezier(.25, .74, .22, .99);
            transition-delay: 1.2s;
            opacity: 0;
            @media (min-width: 1024px) {
                width: 70%;
            }

            h1 {
                font-size: 60px;
                font-weight: 500;
                line-height: 60px;
                color: #FFFFFF;
                font-family: ${title};
                position: relative;
                padding-bottom: 40px;
                margin: 0;
                overflow: hidden;
                text-align: left;
                letter-spacing: unset;
            }

            h3 {
                font-size: 60px;
                font-weight: 500;
                line-height: 60px;
                color: #FFFFFF;
                font-family: ${title};
                position: relative;
                padding-bottom: 40px;
                margin: 0;
                overflow: hidden;
                text-align: left;
            }

            .dc-btn {
                position: relative;
                //transform: translateY(400px);
                transition-delay: 1.2s;
                transform: translateX(50%);

                transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
                opacity: 0;

            }
        }
    }

    //navigation
    .navigation {
        position: absolute;
            //right: ${props => props.offset ? props.offset : '90'}px;
        top: calc(50%);
        transform: translateY(-50%);
        display: inline-block;
        z-index: 1;
        right: 0;
        padding-right: ${props => props.offset ? props.offset : '90'}px;

        ul {
            display: flex;
            flex-direction: column;

            li {
                cursor: pointer;

                &:first-child {
                    margin-bottom: 20px;
                    margin-right: 0px;
                }

                svg {
                    path, #Rectangle_5661 {
                        //transition: stroke-dasharray 0.3s ease-in-out;
                        transition: all 1s cubic-bezier(.25, .74, .22, .99);
                    }
                }

                &:hover {
                    svg {
                        #Rectangle_5661 {
                            opacity: 1;
                            stroke-dasharray: 140;
                        }

                        path {
                            stroke: ${hover};
                        }
                    }
                }
            }
        }
    }

    //responsive
    @media (min-width: 1550px) {
        .slide-inner {
            &__info {
                position: relative;
                overflow: hidden;

                h1 {
                    font-size: 60px;
                    line-height: 70px;

                    .dc-btn {
                        a {
                            font-size: 22px;
                            line-height: 33px;
                        }
                    }
                }

                h3 {
                    font-size: 60px;
                    line-height: 70px;
                }
            }

            .swipper-navigation-slider-custom {
                bottom: 40px;
            }

            .feature-box-container {
                bottom: -150px;
            }

            .feature-box-container {
                .single-item-wrapper {
                    min-height: 300px;

                    .content {
                        min-height: 250px;
                    }
                }
            }
        }

        @media (max-width: 992px) {
            .swipper-navigation-slider-custom {
                bottom: 250px;

            }

            .slide-inner {
                &__info {
                    h1 {
                        font-size: 40px;
                        line-height: 40px;
                        padding-bottom: unset;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) and (min-width: 768px) {
        height: 100vh;

        .navigation {
            display: none;
        }

        .swipper-navigation-slider-custom {
            bottom: 60px;
            left: 45px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: 45%;
            }
        }

        .feature-box-container {
            display: none;
            position: relative;
            left: 0px;
            right: 0px;
            bottom: 200px;
            z-index: 999;
            width: calc(100% - 30px);
            margin: 0px auto;


            .single-item-wrapper a .content {
                align-items: flex-start;
            }

            .feature-box-container_item:last-child {
                order: 1;
                flex: 0 0 100%;
                max-width: 100%;
            }

            .feature-box-container_item:first-child {
                order: 2;
                flex: 0 0 100%;
                max-width: 100%;

                .single-item-wrapper {
                    border-right: none;
                    border-bottom: 1px solid rgba(198, 198, 198, 0.5);
                }

            }

            .feature-box-container_item:nth-of-type(2) {
                order: 3;
                flex: 0 0 50%;
                max-width: 50%;
            }

            .feature-box-container_item:nth-of-type(3) {
                order: 4;
                flex: 0 0 50%;
                max-width: 50%;

                .single-item-wrapper {
                    border-right: none;
                }

            }
        }
    }
    
    @media (max-width: 767px) {
        height: 100vh;
        .desktop {
            display: none;
        }

        .swiper-slide {
            width: 100% !important;
        }

        .mobile {
            display: block;
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: unset;
                transform: unset;
                bottom: 40%;
            }

            &__info {
                position: relative;
                overflow: hidden;
                
                h1 {
                    font-size: 40px;
                    line-height: 48px;
                    padding-bottom: unset;
                }

                h3 {
                    font-size: 40px;
                    line-height: 48px;
                    padding-bottom: unset;
                }

                .dc-btn {
                    margin-top: 30px;
                }
            }
        }

        .swipper-navigation-slider-custom {
            padding-left: 15px !important;
            padding-right: 15px !important;
            bottom: 30px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }
        
        .scrollTo {
            left: 15px;
            right: unset;
            display: inline-block;
            bottom: 20px;
            transform: translateX(0);
        }

        .navigation {
            display: none;
        }
        
        .feature-box-container {
            display: none;
            position: relative;
            bottom: 150px;

            .feature-box-container_item {
                &:first-child {
                    order: 2;
                }

                &:nth-of-type(2) {
                    order: 3;
                }

                &:nth-of-type(3) {
                    order: 4;
                }

                &:last-child {
                    order: 1;

                    .content {
                    }
                }
            }

            .single-item-wrapper {
                border-right: none;
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);

                .content {
                    align-items: flex-start !important;
                }

                &.last {
                    border: none;

                    .content {
                        align-items: center !important;
                    }
                }
            }

            .container {
                padding: 0;
            }
        }
    }
`;

export default React.memo(Banner);
