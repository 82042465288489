import React, { useState } from 'react';
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import styled from "styled-components";




const GalleryComponent = ({ thumbnail, images,title,category,date,monthAndYear }) => {

    return (
        <StyledComponent>
            <LightGallery speed={500}>
                {/* Render only the first image as a thumbnail */}
                <a href={images?.[0]?.full_path}>
                    <img src={images?.[0]?.full_path} alt={`Thumbnail`}/>
                </a>
                {/* Render other images hidden initially */}
                {images.slice(1).map((image, index) => (
                    <a key={index} style={{display: 'none'}} href={image?.full_path}>
                        <img src={image?.full_path} alt={`Image ${index + 1}`}/>
                    </a>
                ))}
            </LightGallery>
            <div className="gallery-content">
                <div className="d-flex">
                    <h5 className={""}>{category}</h5>
                    {date && <h6 className={"meta-font"}>{date}</h6>}


                </div>

                <h6>{title}</h6>
            </div>
            </StyledComponent>

    );
};

const StyledComponent = styled.div`
    border: 1px solid rgba(25, 27, 29, 0.1);
.gallery-content{
    padding: 40px 37px 40px 40px;
    .d-flex{
        justify-content: space-between;
    }
    .meta-font {
        color: #83909d;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }

    h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #ca0034;
        margin-bottom: 20px;
    }

    h6 {
        color: #2b3944;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        letter-spacing: unset;
    }
}

`;

export default GalleryComponent;
