import React, {memo, useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import OverviewList from "../../components/OverviewList";
import Safety from "../../components/Safety";
import Plan from "../../components/Plan";
import Projects from "../../components/services/Projects";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchServiceData} from "../../api/redux/services";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Cta from "../../components/Cta";

const MyComponent = ({offset}) => {
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CHILD_PAGE;

        let api_params = {
            [ApiParam.PAGE_ID]: "10",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.FILE]: "yes",
            [ApiParam.GALLERY]: "yes",
        };

        dispath(fetchServiceData([api_url, api_params]));
    }, [PageAnimation]);

    const getData = useSelector((state) => state.services);
    const autogas = getData?.posts?.data?.list?.find((f) => f?.page_data?.slug === "autogas")
    const overview = autogas?.posts?.list.find((f) => f?.data?.slug === "overview");
    const serviceList = autogas?.posts?.list.find((f) => f?.data?.slug === "service-list");
    const safetyGuidance = autogas?.posts?.list.find((f) => f?.data?.slug === "safety-guidance");
    const projectProcedure = autogas?.posts?.list.find((f) => f?.data?.slug === "project-procedure");
    const cta = autogas?.posts?.list.find((f) => f?.data?.slug === "cta");
    const projects = autogas?.posts?.list.find((f) => f?.data?.slug === "projects");
    const desktop = cta?.images.find((f) => f.desktop === "on")
    const mobile = cta?.images.find((f) => f.mobile === "on")

    return (<StyledComponent>
        <HelmetProvider>
            <motion.div key={`585`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Omera Gas One | Autogas</title>
                <meta name="Pioneering Bangladesh-Japan Energy Partnership for Sustainable LPG Solutions"
                      content="Omera Gas One Ltd. established itself in 2016 as the first Bangladesh-Japan Joint Venture company in the energy sector. The joint collaboration brought together Omera Petroleum Ltd. and Saisan Co., Ltd. to address the growing demand for LPG in Bangladesh's industrial, commercial, and automotive sectors."/>
            </Helmet>
            <InnerBanner
                subtitle={autogas?.page_data?.description}
                breadcrumbs={autogas?.page_data?.subtitle}
                title={autogas?.page_data?.short_desc}
                img={autogas?.images?.list?.find((f) => f.desktop === "on")?.full_path}/>
            <Overview
                description={overview?.data?.description}
                title={overview?.data?.subtitle}/>
            <OverviewList data={serviceList} offset={offset}/>
            <Safety data={safetyGuidance}/>
            <Plan data={projectProcedure} offset={offset}/>
            <Cta
                bg={'#2B3944'}
                buttonText={'Find Filling Stations Near You'}
                link={'/filling-stations'}
                title={cta?.data?.subtitle}
                desktopImg={desktop?.full_path}
                mobileImg={mobile?.full_path}
            />
            {projects && <Projects data={projects}/>}
        </HelmetProvider>
    </StyledComponent>);
};
const StyledComponent = styled.section`

`;

export default memo(MyComponent);
