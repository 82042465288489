import React, {memo, useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import AddressTab from "../../components/contact/AddressTab";
import ContactForm from "../../components/contact/ContactForm";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchContactData} from "../../api/redux/contact";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";

const Contact = () => {
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "contact",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.PAGE_ID]: "10",
        };

        dispath(fetchContactData([api_url, api_params]));
    }, []);

    const getData = useSelector((state) => state.contact);
    const address = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "address");
    const form = getData?.posts?.data?.sections?.find((f) => f?.section_data?.slug === "form");

    return (
        <HelmetProvider>
            <motion.div key={`587`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Omera Gas One | Contact</title>
                <meta name="We value your feedback" content="We value your feedback"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    subtitle={getData?.posts?.data?.page_data?.short_desc}
                    breadcrumbs={getData?.posts?.data?.page_data?.breadcumb} title={getData?.posts?.data?.page_data?.subtitle}
                    img={getData?.posts?.data?.page_images?.list?.[0]?.full_path}/>
                <AddressTab data={address} />
                <ContactForm data={form} />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.div`

`;

export default memo(Contact);
