import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import ProjectItem from "../ProjectItem";
const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-160 '}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="project-title">
                            <h3 className={'split-up'}>Projects</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        data?.images?.map((item, index) => {
                            return (
                                <Col sm={4}>
                                    <ProjectItem id={index + 1} title={item?.short_title} desc={item?.short_desc}
                                                 img={item?.full_path}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>);
};

const StyledComponent = styled.section`
  padding-bottom: 130px;

  .project-title {
    padding-bottom: 60px;
  }

  .loadMore {
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }

  h3 {
    color: ${hover}
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    padding-bottom: 90px;
    .project-title {
      padding-bottom: 40px;

      h3 {
        font-size: 28px;
        font-weight: 400;
        line-height: 36px;
      }
    }

    .loadMore {
      padding-top: 10px;
      justify-content: start;
    }
  }
`;

export default React.memo(MyComponent);
