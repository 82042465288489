import React, {memo, useEffect} from "react";
import styled from "styled-components";
import {HelmetProvider, Helmet} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import ZigZag from "../../components/ZigZag";
import Invest from "../../components/Invest";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchUpsData} from "../../api/redux/ups";
import transition from "../../transition";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import {gsap} from "gsap";


const Ups = ({offset}) => {
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "usp-detail",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
        };

        dispath(fetchUpsData([api_url, api_params]));
    }, [PageAnimation]);
    const getData = useSelector((state) => state.ups);

    const zigZag = getData?.posts?.data?.sections?.find(
        (f) => f?.section_data?.slug === "zigzag"
    );
    const innerBanner = getData?.posts?.data?.page_data;
    const innerImage = getData?.posts?.data?.page_images?.list?.[0]?.full_path;


    useEffect(() => {
        const tl = gsap.timeline();

        if (window.innerWidth > 767) {
            let ctx = gsap.context(() => {
                // Pinning at top: 0 when scrolling down
                tl.to('.scroll-wrapper', {
                    scrollTrigger: {
                        id: '#scroll-nav',
                        trigger: '.scroll_to',
                        start: 'top top+=0',
                        endTrigger: '.scroll-wrapper',
                        end: 'bottom-=250px top',
                        pin: true,
                        pinSpacing: false,
                        onUpdate: self => {
                            // Check if scrolling up (direction -1)
                            if (self.direction === -1) {
                                // Update the top value to 120 when scrolling up
                                gsap.to('.scroll_to', {marginTop: 0, duration: 1});

                            }

                            if (self.direction === 1 || window.scrollY < 500) {

                                // Reset the top value to 0 when scrolling down or not scrolling
                                gsap.to('.scroll_to', {marginTop: -2, duration: 1});
                            }
                        }
                    },
                });
            });
            return () => ctx.kill(); // <-- cleanup!

        }

    }, [getData]);

    return (
        <HelmetProvider>
            <motion.div key={`582`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Omera Gas One | USP</title>
                <meta name="Path to Sustainability" content="Dedicated to Excellence in LPG Services"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    buttonIcon={40}
                    subtitle={innerBanner?.description}
                    breadcrumbs={innerBanner?.subtitle}
                    title={innerBanner?.short_desc}
                    img={innerImage}
                    mb={60}
                />
                <ZigZag dataSpeed={0.9} data={zigZag} offset={offset}/>
                <Invest id={'invest'}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    /* small mobile :320px. */
`;

export default memo(Ups);
