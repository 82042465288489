import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import LocationDropdown from "../LocationDropdown";
import SingleAddress from "../SingleAddress";

const MyComponent = ({data, districts, division, onDivisionChange, onDistrictChange}) => {
    return (
        <StyledComponent className={`pt-120 pb-120 ${data?.length < 4 ? 'custom-class' : ''}`}>
            <Container>
                <Row className={'location-dropdown'}>
                    <Col lg={6} md={6}>
                        <LocationDropdown handleOnChange={onDivisionChange} options={division} placeholder={'Division'}/>
                    </Col>
                    <Col lg={6} md={6}>
                        <LocationDropdown handleOnChange={onDistrictChange} options={districts} placeholder={'District'}/>
                    </Col>
                </Row>
                <Row className={'location-list'}>
                    {
                        data?.map((item, index) => {
                            return (
                                <Col key={index} sm={4}>
                                    <SingleAddress name={item?.name} address={item?.address} phone={item?.phone_number} mapLink={item?.map_link} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  overflow: hidden;
    min-height: 100vh;
    &.custom-class{
        min-height: 70vh;
    }
  .location-list {
    padding-top: 60px;
      
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    .location-dropdown {
      gap: 30px;
    }
  }
`;

export default React.memo(MyComponent);
