import React from "react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const Strength = ({title, desc, img, id}) => {
    return (
        <StyledStrength>
            <div className="about-section__bottom__single p-0">
                <div className="about-section__bottom__single__inner">
                    <div className="about-section__bottom__single__inner__img">
                        <Img src={img}/>
                    </div>

                    <h1>{id}</h1>
                    <h4>{ReactHtmlParser(title)}</h4>
                    <p>{ReactHtmlParser(desc)}</p>

                    <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="12.828" viewBox="0 0 17.414 12.828">
                            <line id="Line_3858" data-name="Line 3858" x2="5" y2="5" transform="translate(11 1.414)" fill="none" stroke="#f8f8f9" stroke-linecap="round" stroke-width="2"/>
                            <line id="Line_3859" data-name="Line 3859" y1="5" x2="5" transform="translate(11 6.414)" fill="none" stroke="#f8f8f9" stroke-linecap="round" stroke-width="2"/>
                            <line id="Line_3860" data-name="Line 3860" x1="15" transform="translate(1 6.414)" fill="none" stroke="#f8f8f9" stroke-linecap="round" stroke-width="2"/>
                        </svg>

                    </div>
                </div>
            </div>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;

  .about-section__bottom {
    //margin-top: -50px;
    position: relative;
    padding-right: 0;

    .row {
      margin-left: 0px;
      margin-right: 0px;
    }


    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      background: #088395;
      height: 50%;
      width: 100%;
    }


    &__single {
      max-width: 100%;
      overflow: hidden;

      &__inner {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        overflow: hidden;

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: "";
            position: absolute;
            height: 0%;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #9CABB9;
            transition: 0.5s cubic-bezier(0.4, 0, 0, 1);
          }
        }


        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: 0.3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }
        h1 {
          position: absolute;
          bottom: 40px;
          left: 30px;
          right: 30px;
          top: 30px;
          color: #ffffff;
          z-index: 0;
          transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
        }

        h4 {
          position: absolute;
          bottom: 40px;
          left: 30px;
          right: 30px;
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          color: #ffffff;
          z-index: 5;
          transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #ffffff;
          z-index: 5;
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all 0.8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all 0.8s cubic-bezier(0.4, 0, 0, 1);
            }
          }
        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 60px;
          font-weight: 500;
          line-height: 60px;
          color: #f9f9f9;
          transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &:hover {
        .about-section__bottom__single__inner h1 {
          opacity: 0;
        }

        .about-section__bottom__single__inner p {
          top: 70px;
          opacity: 1;
        }

        .about-section__bottom__single__inner__img::after {
          height: 100%;
        }

        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #fff;
                fill: #fff;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }

          h4 {
            color: #ffffff !important;
          }

          p {
            transform: none;
            opacity: 1;
            color: #ffffff;
          }

          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }


        }

      }
    }
  }

  /* Normal desktop :1920. */
  @media (min-width: 1920px) {
    .navigation_wrapper {
      .title {
        h3 {
          font-size: 48px !important;
        }
      }
    }

  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {

    .swiper-initialized {
      padding-right: 40px;
    }

    .about-section__bottom {
      .row {
        margin-left: 40px !important;
      }
    }

  }


  @media (max-width: 767px) {
    .about-section__bottom {
      padding-right: 15px;
      margin-left: unset !important;
    }

    .swiper-initialized {
      padding-right: 1px;
    }

    .title h3 {
      font-size: 28px !important;
      font-weight: 500;
      line-height: 40px;
    }
  }

`;

export default React.memo(Strength);
