import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/swiper.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TeamBox from "./TeamBox";
import NextBtn from "./NextBtn";

// SwiperCore.use([Autoplay, Pagination, Navigation]);

const Strength = ({offset, data}) => {
    const peoples = data?.posts?.list;
    return (
        <StyledStrength  offset={offset} >
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <h2>{`${data?.section_data?.subtitle} ${data?.section_data?.short_desc}`}</h2>
                            </div>
                            <NextBtn prevId={'banner-prev'} nextId={'banner-next'} borderColor={'#2B3944'}
                                     iconColor={'#2B3944'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        slideNextClass={'.next'}
                        allowSlideNext={true}
                        slidePrevClass={'.prev'}
                        allowSlidePrev={true}
                        loop={false}
                        // allowTouchMove={true}
                        longSwipesMs={900}
                        speed={900}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        navigation={{
                            prevEl: '#banner-prev',
                            nextEl: '#banner-next',
                        }}
                        modules={[Navigation]}
                    >
                        {
                            peoples?.map((item) => {
                                return (
                                    <Col md={4}>
                                        <SwiperSlide key={0}>
                                            <TeamBox md description={item?.data?.description} title={item?.data?.subtitle}
                                                     des={item?.data?.short_desc}
                                                     img={item?.images?.[0]?.full_path ? item?.images?.[0]?.full_path : 'images/static/blur.jpg'} />
                                        </SwiperSlide>
                                    </Col>
                                )
                            })
                        }

                    </Swiper>
                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

    position: relative;
    overflow: hidden;
    
    .swiper-initialized {
        padding-right: 90px;
        @media (max-width: 767px) {
            //padding-right: 0 !important;
            width: 100%;
        }
    }

    .navigation_wrapper {
        flex-direction: column;
    }

    .swiper-wrapper {
        height: auto !important;
    }

    .about-section__bottom {
        padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
        position: relative;

        @media (min-width: 768px) and (max-width: 991px) {
            padding-left: ${props => props.offset ? props.offset + 55 + 'px' : '90px'};
        }


        .single_wrp {
            position: relative;
            overflow: hidden;
            padding-top: calc(330 / 270 * 100%);

            .global-image {
                img {
                    transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
                    //transform: scale(1.01);
                }
            }
        }


        @media (max-width: 767px) {
            padding-left: 30px !important;
            //padding-right: 30px !important;
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        padding-bottom: 120px !important;
        .view_btn {
            display: none;
        }

        h2 {
            font-size: 32px;
            font-weight: 400;
            letter-spacing: unset;
            line-height: 40px;
        }

        .navigation {
            min-width: 100%;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .about-section__bottom__single__inner span {
            top: 55px;
            left: 55px;
        }
    }
    

    @media (max-width: 767px) {
        padding-bottom: 120px !important;

        //.swiper-slide {
        //    width: 270px !important;
        //}
        
        .view_btn {
            display: none;
        }

        h2 {
            font-size: 32px;
            font-weight: 400;
            letter-spacing: unset;
            line-height: 40px;
        }

        .navigation {
            min-width: 100%;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .about-section__bottom__single__inner span {
            top: 55px;
            left: 55px;
        }
    }


`;

export default React.memo(Strength);
