import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Call from "./Call";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, brands}) => {
    let currentYear = new Date().getFullYear()

    const socials = [
        {
            id: 1,
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.539"
                    height="12.208"
                    viewBox="0 0 6.539 12.208"
                >
                    <path
                        id="Path_2115"
                        data-name="Path 2115"
                        d="M1204.9,102.967l.339-2.21h-2.12V99.323a1.1,1.1,0,0,1,1.246-1.194h.964V96.248a11.752,11.752,0,0,0-1.711-.149,2.7,2.7,0,0,0-2.887,2.974v1.684h-1.941v2.21h1.941v5.341h2.388v-5.341Z"
                        transform="translate(-1198.787 -96.099)"
                        fill="#f8f8f9"
                    />
                </svg>
            ),
            link: data?.facebook,
            background: '#1877F2'
        },
        {
            id: 2,
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.035"
                    height="10.328"
                    viewBox="0 0 11.035 10.328"
                >
                    <path
                        id="path1009"
                        d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                        transform="translate(-281.5 -167.31)"
                        fill="#f8f8f9"
                    />
                </svg>
            ),
            link: data?.twitter,
            background: 'rgba(15,20,25,1.00)'
        },
        {
            id: 3,
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.133"
                    height="12.133"
                    viewBox="0 0 12.133 12.133"
                >
                    <path
                        id="Path_2110"
                        data-name="Path 2110"
                        d="M1082.043,108.605a2.988,2.988,0,1,0,2.988,2.988A2.992,2.992,0,0,0,1082.043,108.605Zm0,4.9a1.914,1.914,0,1,1,1.914-1.914A1.916,1.916,0,0,1,1082.043,113.507Z"
                        transform="translate(-1075.927 -105.527)"
                        fill="#f8f8f9"
                    />
                    <path
                        id="Path_2111"
                        data-name="Path 2111"
                        d="M1078.421,111.761h-4.844a3.649,3.649,0,0,1-3.645-3.645v-4.844a3.649,3.649,0,0,1,3.645-3.644h4.844a3.649,3.649,0,0,1,3.645,3.644v4.844A3.649,3.649,0,0,1,1078.421,111.761Zm-4.844-10.992a2.506,2.506,0,0,0-2.5,2.5v4.844a2.506,2.506,0,0,0,2.5,2.5h4.844a2.506,2.506,0,0,0,2.5-2.5v-4.844a2.506,2.506,0,0,0-2.5-2.5Z"
                        transform="translate(-1069.932 -99.628)"
                        fill="#f8f8f9"
                    />
                </svg>
            ),
            link: data?.instagram,
            background: 'linear-gradient(to right, #833AB4, #E1306C);'
        },
        {
            id: 4,
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.685"
                    height="7.479"
                    viewBox="0 0 10.685 7.479"
                >
                    <path
                        id="Path_2114"
                        data-name="Path 2114"
                        d="M1144.76,112.662a2.345,2.345,0,0,0-2.345-2.345h-6a2.345,2.345,0,0,0-2.344,2.345v2.79a2.345,2.345,0,0,0,2.344,2.345h6a2.345,2.345,0,0,0,2.345-2.345Zm-3.526,1.6-2.689,1.33c-.105.057-.463-.019-.463-.139v-2.73c0-.121.361-.2.466-.137l2.574,1.4C1141.23,114.051,1141.343,114.207,1141.234,114.266Z"
                        transform="translate(-1134.075 -110.317)"
                        fill="#f8f8f9"
                    />
                </svg>
            ),
            link: data?.youtube,
            background: '#FF0000'
        },
        {
            id: 5,
            icon: (
                <svg
                    id="_x31_0.Linkedin"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.314"
                    height="10.313"
                    viewBox="0 0 10.314 10.313"
                >
                    <path
                        id="Path_2123"
                        data-name="Path 2123"
                        d="M44.965,42.352V38.575c0-1.856-.4-3.275-2.565-3.275a2.239,2.239,0,0,0-2.024,1.109H40.35v-.941H38.3v6.884h2.14V38.936c0-.9.168-1.766,1.276-1.766s1.109,1.018,1.109,1.818v3.352h2.14Z"
                        transform="translate(-34.652 -32.038)"
                        fill="#f8f8f9"
                    />
                    <path
                        id="Path_2124"
                        data-name="Path 2124"
                        d="M11.3,36.6h2.14v6.884H11.3Z"
                        transform="translate(-11.132 -33.171)"
                        fill="#f8f8f9"
                    />
                    <path
                        id="Path_2125"
                        data-name="Path 2125"
                        d="M11.238,10a1.244,1.244,0,1,0,1.238,1.238A1.238,1.238,0,0,0,11.238,10Z"
                        transform="translate(-10 -10)"
                        fill="#f8f8f9"
                    />
                </svg>
            ),

            link: data?.linkedin,
            background: '#0077B5'
        },
    ];
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 767);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount
    return (
        isDesktop ? (
            <StyledDesktop className={'desktop'}>
                <Container>
                    <div className="footer-top">
                        <Row>
                            <Col sm={3} className={'first-section'}>
                                <div className="title">Find Us</div>
                                <ul>
                                    {
                                        data?.office_location && <li>
                                            <a href={`https://www.google.com/maps?q=${data?.office_location}`}
                                               target={'_blank'}>
                                                {reactHtmlParser(data?.office_location)}
                                            </a>
                                        </li>
                                    }
                                    {
                                        data?.contact_email && <li>
                                            <a href={`mailto:${data?.contact_email}`}>{data?.contact_email}</a>
                                        </li>
                                    }
                                    {
                                        data?.office_phone && <li>
                                            <div className={'title'}>Customer
                                                Service
                                            </div>
                                            <a href={`tel:${data?.office_phone}`}>{data?.office_phone} </a>
                                            <a href={`tel:${data?.office_fax}`}>{data?.office_fax} </a>
                                        </li>
                                    }
                                </ul>
                            </Col>
                            <Col className={"list"} sm={{offset: 1, span: 2}}>
                                <div className="title">Navigation</div>
                                <ul className={"list-item"}>
                                    <li>
                                        <Link to={"/"}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to={"/about"}>Omera Gas One Limited</Link>
                                    </li>
                                    <li>
                                        <Link to={"/about#management-team"}>
                                            Board of Directors
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/media-center"}>Media Center</Link>
                                    </li>
                                    <li>
                                        <Link to={"/contact"}>Contact Us</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col className={"list"} sm={{offset: 1, span: 3}}>
                                <div className="title">Services</div>
                                <ul className={"list-item"}>
                                    <li>
                                        <Link to={"/autogas"}>Autogas</Link>
                                    </li>
                                    <li>
                                        <Link to={"/industrial"}>Industrial</Link>
                                    </li>
                                    <li>
                                        <Link to={"/residential"}>Residential</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col className={"text-right desktop"} sm={2}>
                                <ul className={"icons"}>
                                    <Call
                                        height={30}
                                        width={30}
                                        hoverBackgroundColor={"#CA0034"}
                                        backgroundColor={socials[0]?.background}
                                        icon={socials[0]?.icon}
                                        url={socials[0]?.link}
                                        target={'_blank'}
                                    />
                                    <Call
                                        height={30}
                                        width={30}
                                        hoverBackgroundColor={"#CA0034"}
                                        backgroundColor={socials[3]?.background}
                                        icon={socials[3]?.icon}
                                        url={socials[3]?.link}
                                        target={'_blank'}
                                    />
                                    <Call
                                        height={30}
                                        width={30}
                                        hoverBackgroundColor={"#CA0034"}
                                        backgroundColor={socials[4]?.background}
                                        icon={socials[4]?.icon}
                                        url={socials[4]?.link}
                                        target={'_blank'}
                                    />

                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-bottom">
                        <Row>
                            <Col sm={3}>
                                <p>© {currentYear} Omera Gas One Ltd. <br/> All Rights Reserved.</p>
                            </Col>
                            <Col sm={{offset: 1, span: 3}}>
                                <p>
                                    <a target="_blank" href="https://www.dcastalia.com">
                                        Designed & Developed by Dcastalia
                                    </a>{" "}
                                </p>
                            </Col>
                            <Col sm={{span: 5}}>
                                <ul className={"footer-brands"}>
                                    {brands && brands?.length > 0 && brands?.map(item => {
                                        return (
                                            <li key={item?.id}>
                                                <a target={"_blank"} href={item?.short_title}>
                                                    <img src={item?.full_path} alt="title"/>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </StyledDesktop>
        ) : (
            <StyledMobile className={'mobile'}>
                <Container>
                    <div className="footer-top">
                        <Row>
                            <Col sm={3}>
                                <div className="title">Find Us</div>
                                <ul className={'mb-40'} >
                                    {
                                        data?.office_location && <li>
                                            <a href={`${data?.office_location_url}`}>
                                                {reactHtmlParser(data?.office_location)}
                                            </a>
                                        </li>
                                    }
                                    {
                                        data?.contact_email && <li>
                                            <a href={`mailto:${data?.contact_email}`}>{data?.contact_email}</a>
                                        </li>
                                    }
                                </ul>
                                <div className="title">Customer
                                    Service
                                </div>
                                <ul>
                                    {
                                        data?.office_phone && <li>

                                            <a href={`"tel:${data?.office_phone}"`}>{data?.office_phone} </a>
                                        </li>
                                    }
                                    {
                                        data?.office_fax && <li>
                                            <a href="#">{data?.office_fax} </a>
                                        </li>
                                    }
                                </ul>
                            </Col>
                            <Col className={"text-right desktop"} sm={2}>
                                <ul className={"icons"}>
                                    {socials?.map((item) => {
                                        return (
                                            item?.link && <li key={item.id}>
                                                <a target={"_blank"} href={item.link}>
                                                    {item.link && <Call
                                                        height={30}
                                                        width={30}
                                                        hoverBackgroundColor={"#CA0034"}
                                                        backgroundColor={item?.background}
                                                        icon={item.icon}
                                                        link={item?.link}
                                                    />}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                            <Col sm={{offset: 1, span: 2}}>
                                <div className="title">Navigation</div>
                                <ul>
                                    <li>
                                        <Link to={"/"}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to={"/about"}>Omera Gas One Limited</Link>
                                    </li>
                                    <li>
                                        <Link to={"/about#management-team"}>
                                            Board of Directors
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/media-center"}>Media Center</Link>
                                    </li>
                                    <li>
                                        <Link to={"/contact"}>Contact Us</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col className={"list"} sm={{offset: 1, span: 3}}>
                                <div className="title">Services</div>
                                <ul>
                                    <li>
                                        <Link to={"/autogas"}>Autogas</Link>
                                    </li>
                                    <li>
                                        <Link to={"/industrial"}>Industrial</Link>
                                    </li>
                                    <li>
                                        <Link to={"/residential"}>Residential</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-bottom">
                        <Row>
                            <Col sm={12}>
                                <ul className={"footer-brands"}>
                                    {brands && brands?.length > 0 && brands?.map(item => {
                                        return (
                                            <li key={item?.id}>
                                                <a target={"_blank"} href={item?.short_title}>
                                                    <img src={item?.full_path} alt=""/>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                            <Col className={"develop"} sm={12}>
                                <p>
                                    <a target={"_blank"} href="https://dcastalia.com/">
                                        Designed & Developed by Dcastalia
                                    </a>{" "}
                                </p>
                            </Col>
                            <Col sm={12}>
                                <p>© {currentYear} Omera Gas One Ltd. <br/> All Rights Reserved.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </StyledMobile>
        )
    )
};

const StyledDesktop = styled.section`
    padding: 60px 0px 30px 0px;
    background-color: #2b3944;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;

    .footer-top {
        border-bottom: 1px solid rgba(248, 248, 249, 0.2);
        padding-bottom: 30px;
    }

    .title {
        margin-bottom: 20px;
    }

    .first-section {
        .title {
            margin-bottom: 20px;
        }

        ul {
            li {
                display: flex;
                flex-direction: column;

                p {
                    margin-bottom: 10px;
                }
            }
        }
    }

    ul li a:hover {
        color: rgba(248, 248, 249, 0.5) !important;
    }

    .desktop {
        display: block;
    }

    .title {
        color: rgba(248, 248, 249, 0.5);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    ul {
        display: flex;
        flex-direction: column;

        li {
            margin-bottom: 20px;

            a {
                color: #f8f8f9;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;
                width: fit-content;
            }
        }
    }

    ul.icons {
        flex-direction: row;
        justify-content: end;
        gap: 15px;
        padding: 0px;
    }

    .footer-bottom {
        padding-top: 30px;

        p,
        a {
            color: rgba(248, 248, 249, 0.5);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.24px;
        }
    }

    .footer-bottom a:hover {
        color: #fff !important;
    }

    ul.footer-brands {
        flex-direction: row;
        padding-top: 0px;

        li {
            padding-right: 10px;
        }

        li:nth-of-type(1) {
            padding-right: 30px;
            border-right: 1px solid #f8f8f9;
        }

        li:nth-of-type(2) {
            padding-left: 10px;
        }
    }
`;

const StyledMobile = styled.section`
    padding: 80px 0px 40px 0px;
    background-color: #2b3944;
    border-bottom: 1px solid gray;

    .list {
        padding-top: 40px;
    }

    .footer-top {
        border-bottom: 1px solid rgba(248, 248, 249, 0.2);
        padding-bottom: 30px;
        padding-top: 0px;
    }

    .develop {
        padding-bottom: 15px;
    }

    .list:nth-of-type(1) {
        padding-bottom: 40px !important;
    }

    .title {
        color: rgba(248, 248, 249, 0.5);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    ul li a:hover {
        color: rgba(248, 248, 249, 0.5) !important;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 20px;

        li {
            a {
                color: #f8f8f9;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                width: fit-content;
            }
        }
    }

    ul.icons {
        flex-direction: row;
        gap: 15px;
        padding: 40px 0px;
    }

    .footer-bottom {
        padding-top: 30px;

        p,
        a {
            color: rgba(248, 248, 249, 0.5);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.24px;
        }
    }

    .footer-bottom a:hover {
        color: #fff !important;
    }

    ul.footer-brands {
        flex-direction: row;
        padding-bottom: 40px;
        padding-top: 0;

        li a img {
            width: 50px;
        }

        li:nth-of-type(1) {
            padding-right: 30px;
            border-right: 1px solid #f8f8f9;
        }

        li:nth-of-type(2) {
            padding-left: 10px;
        }
    }
`;

export default React.memo(MyComponent);
