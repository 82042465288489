import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";

import {hover} from "../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {Link, useLocation} from "react-router-dom";
import Button from "./Button";
import Call from "./Call";


const icon = <svg xmlns="http://www.w3.org/2000/svg" width="12.082" height="12.964" viewBox="0 0 12.082 12.964">
    <path id="Path_11" data-name="Path 11"
          d="M11.6,9.023c-.783-.67-1.578-1.076-2.351-.407l-.462.4c-.338.293-.966,1.664-3.4-1.13s-.983-3.226-.645-3.517l.464-.4c.769-.67.479-1.514-.076-2.383L4.8,1.06C4.243.193,3.636-.376,2.864.293L2.447.658A3.913,3.913,0,0,0,.922,3.247c-.278,1.841.6,3.948,2.611,6.261s3.975,3.476,5.838,3.455a3.924,3.924,0,0,0,2.775-1.149l.418-.365c.769-.669.291-1.35-.492-2.021Z"
          transform="translate(-0.872 0)" fill="#f8f8f9"/>
</svg>

const MainMenu = () => {
    const location = useLocation();
    const mobileMenuRaf = useRef();
    const menuRef = useRef();
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();
    const containerRef = useRef();


    const activeSlugs = ['/autogas', '/residential', '/Industrial'];
    const subSlugs = ['/autogas', '/residential', '/Industrial'];

    // desktop contact action
useEffect(() => {
        // menu click on mobile

        function calculateHeight() {
            let vh = window.innerHeight * 0.01;
            document?.documentElement?.style.setProperty('--vh', `${vh}px`);
            let menuCloseAction = document?.querySelector('.mobile-menu__items');
            if (menuCloseAction) {
                menuCloseAction.style.height = `${vh * 100}px`;
            }
        }

        window.addEventListener('resize', calculateHeight);            //to stop scrolling after clicking the menu button

        hamburgerRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-visible");
            document.body.classList.add("menu-showed");
            calculateHeight()
            gsap.to(menuRef.current, {
                display: "block",
            });
            gsap.to(menuRef.current, {
                opacity: 1,
                duration: ".3",
            });
        });

        hamburgerCloseRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
            // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
            document.body.classList.remove('contact-slided')
            document.body.classList.remove("menu-showed");
            gsap.to(menuRef.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef.current, {
                display: "none",
            });
        });

        let getLinkTag = document.querySelectorAll("a");

        for (let i of getLinkTag) {
            i.addEventListener("click", () => {
                document.querySelector(".hamburger").classList.remove("menu-open");
                document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
                // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
                document.body.classList.remove('contact-slided')
                document.body.classList.remove("menu-showed");
                gsap.to(menuRef.current, {
                    opacity: 0,
                    duration: ".2",
                });
                gsap.to(
                    menuRef.current,
                    {
                        display: "none",
                    },
                    "-=.2"
                );

                gsap.to('.desktop-menu__bottom ul li ul', {
                    opacity: 0,
                    visibility: 'hidden',
                    top: '70px',
                    duration: .2,
                    zIndex: -2
                })
                setTimeout(() => {
                    let getAllSub = document.querySelectorAll('.desktop-menu__bottom ul li ul');
                    getAllSub.forEach(function (e) {
                        e.removeAttribute('style')
                    })

                }, 800)

            });
        }

    }, []);

    // mobile contact slide
    useEffect(() => {
        document.querySelector('.mobile-menu__items__contact').addEventListener('click', function () {
            document.body.classList.add('contact-slided')
        });
    }, [])

    useEffect(() => {
        gsap.timeline()
            .to('.main-menu', {
                opacity: 0,
                duration: 0.1,
            })
            .to('.main-menu', {
                opacity: 1,
                delay: 0.75,
                duration: 0.5
            });
    }, [location.pathname]);

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);


    return (
        <>
            <StyledDesktopMenu className="desktop-menu main-menu">
                <div className={'desktop-bg'}>
                    <Container ref={containerRef}>
                        <Row>
                            <Col sm={6}>
                                <div className="desktop-left">
                                    <Link to={"/"}>
                                        <img
                                            alt="logo"
                                            src={'/images/static/logo.svg'}
                                        />
                                    </Link>
                                    <div className="desktop-menu__bottom">
                                        <ul>
                                            <li className={location.pathname === '/' ? 'active' : ''}>
                                                <Link to={"/"}>
                                                    <span>Home</span>
                                                </Link>
                                            </li>
                                            <li className={`has-child ${location.pathname === '/about' ? 'active' : ''}`}>
                                                <Link to={"/about"}>
                                                    <span>About</span>
                                                </Link>

                                            </li>
                                            <li className={`has-child ${location.pathname === '/usp-detail' ? 'active' : ''}`}>
                                                <Link to={"/usp-detail"}>
                                                    <span>USP</span>
                                                </Link>

                                            </li>
                                            <li className={`has-child ${activeSlugs.includes(location.pathname) ? 'active' : ''}`}>
                                                <span>Service </span>
                                                {/*<FaChevronDown/>*/}
                                                <ul>
                                                    <li>
                                                        <Link
                                                            className={`has-child ${subSlugs.includes(location.pathname) ? 'active-sub' : ''}`}
                                                            to={"/autogas"}> Autogas
                                                        </Link>
                                                    </li>
                                                    <li className={`has-child ${subSlugs.includes(location.pathname) ? 'active-sub' : ''}`}>
                                                        <Link
                                                            className={'active'}
                                                            to={"/Industrial"}> Industrial
                                                        </Link>
                                                    </li>
                                                    <li className={`has-child ${subSlugs.includes(location.pathname) ? 'active-sub' : ''}`}>
                                                        <Link classsName={'active'}
                                                              to={"/residential"}> Residential
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>


                                            <li className={location.pathname === '/media-center' ? 'active' : ''}>
                                                <Link to={"/media-center"}>
                                                    <span>Media</span>
                                                </Link>
                                            </li>

                                            <li className={location.pathname === '/contact' ? 'active' : ''}>
                                                <Link to={"/contact"}>

                                                    <span>Contact</span>

                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className={'d-flex justify-content-end right'} sm={6}>
                                <Call url={"tel:+8809610908070"} icon={icon}/>
                                <Button src={'/filling-stations'} height={37} hoverBackground={'#2B3944'}
                                        color={'#F8F8F9'}
                                        text={'Filling Stations'}/>
                            </Col>
                        </Row>
                    </Container>

                </div>

            </StyledDesktopMenu>

            {/*mobile menu */}
            <StyledMobileMenu className="Mobile-menu-wrap menu-bar">
                <Container>
                    <Row className="mobile-menu" ref={mobileMenuRaf}>
                        <Col className="logo">
                            <Link to={"/"}>

                                <img src={'/images/static/logo.svg'}/>
                                <img src={'/images/static/mb-logo.svg'}/>

                            </Link>
                        </Col>

                        <Col className="hamburger">
                          <span ref={hamburgerRef}>
                            <img src={'/images/static/hamburger.svg'}/>
                          </span>

                            <span ref={hamburgerCloseRef}>
                            <img src={'/images/static/close-btn.svg'}/>
                          </span>
                        </Col>

                        <div className="mobile-menu__items" ref={menuRef}>


                            <div className="mobile-menu__items__contact">
                                <p><Link to={'/filling-stations'} >Filling Stations</Link></p>
                            </div>


                            <ul className="mobile-menu__items__ul">
                                <li className={location.pathname === '/' ? 'active' : ''}>
                                    <Link to={"/"}>
                                        Home
                                    </Link>

                                </li>

                                <li className={location.pathname === '/about' ? 'active' : ''}>
                                    <Link to={"/about"}>
                                        About
                                    </Link>
                                </li>
                                <li className={location.pathname === '/usp-detail' ? 'active' : ''}>
                                    <Link to={"/usp-detail"}>
                                        USP
                                    </Link>
                                </li>
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className={`has-child ${location.pathname === '/Service' ? 'active' : ''}`}>
                                            Service <img src={'/images/static/down.svg'}/>{" "}
                                            <img src={'/images/static/up.svg'}/>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    <Link to={"/autogas"}> Autogas
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/industrial"}> Industrial </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/residential"}> Residential
                                                    </Link>
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <li className={location.pathname === '/media-center' ? 'active' : ''}>
                                        <Link to={"/media-center"}>
                                            Media Center
                                        </Link>
                                    </li>

                                    <li className={location.pathname === '/contact' ? 'active' : ''}>
                                        <Link to={"/contact"}>
                                            Contact
                                        </Link>
                                    </li>
                                </Accordion>
                            </ul>
                        </div>

                    </Row>
                </Container>
            </StyledMobileMenu>
        </>
    );
};

const StyledDesktopMenu = styled.section`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    transition: all 0.5s ease 0s;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(249, 249, 249);
    box-shadow: rgba(0, 12, 121, 0.1) 0px 30px 80px, rgba(0, 12, 121, 0.1) 0px 12px 24px;
  .right {
    gap: 20px;
    align-items: center;
  }

  .desktop-left {
    display: flex;
    align-items: center;
    gap: 60px;

    img {
      transition: height 0.3s;
      min-width: 120px;
      height: 80px;
    }
  }

  //bottom menu
  .desktop-menu__bottom {
    height: 60px;

    ul {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 30px;
        align-items: center;
      li {
        display: flex;
        cursor: pointer;
        position: relative;
          
        span {
          width: 100%;
          position: relative;
          z-index: 2;
          color: rgb(202, 0, 52);
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.24px;
            transition: all 0.1s ease-in-out;
        }

        > svg {
          color: #FFF;
          position: absolute;
          right: 20px;
          top: 5px;
          bottom: 0px;
          font-size: 12px;
          line-height: 24px;
          //margin: auto;
        }

        a {
          font-size: 12px;
          font-weight: 400;
          display: flex;
          width: 100%;
          line-height: 18px;
          color: #ffffff;
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        ul {
          position: absolute;
          flex-wrap: wrap;
          top: 70px;
          background: #F8F8F9;
          width: 319px;
          z-index: 5;
          padding: 15px 0 15px 0;
          opacity: 0;
          height: auto;
          display: block;
          visibility: hidden;
          transition: all 0.5s ease-in-out;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

          li {
            display: block;
            min-width: 100%;
            border: none;
            padding: 0 30px 0 30px;
            //opacity: 0;

            :last-child {
              a {
                &:after {
                  display: none;
                }
              }
            }

            a {
              display: flex;
              padding: 15px 0 15px 0;
              //border-bottom: 1px solid rgba(34, 31, 31, 0.1);
              color: #2B3944;
              width: 100%;
              margin-bottom: 0;
              position: relative;
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;

              svg {
                position: absolute;
                right: 30px;
                color: #2B3944;
                opacity: 0;
                transition: all 0.5s ease-in-out;
                top: 0;
                bottom: 0;
                margin: auto;

              }

              &:after {
                content: '';
                position: absolute;
                height: 1px;
                background-color: rgba(43, 57, 68, 0.2) !important;
                left: 0px;
                right: 0px;
                bottom: 0;
              }

              &:hover {
                color: #CA0034 !important;
              }
            }

            &:after {
              display: none !important;
            }


            &:nth-last-child(1) {
              a {
                margin-bottom: 0;
                border: none;
                padding-bottom: 15px;
              }
            }

            &:hover {
              svg {
                right: 0;
                opacity: 1;
              }
            }

            ul {
              display: block !important;
              height: auto !important;
              position: absolute;
              background-color: #F2F2F2;
              width: 270px;
              z-index: 2;
              left: calc(100% + 0px);
              top: -5px;
              opacity: 0;
              transition: all 0.5s ease-in-out;

              &:before {
                content: none;
              }

              li {
                opacity: 1 !important;
              }
            }

            &:hover {
              ul {
                opacity: 1 !important;
              }
            }
          }
        }

        &.active {
          //&:after {
          //  width: 100%;
          //}
        }

        &:hover {
          &:after {
            width: 100%;
          }

          ul {
            opacity: 1;
            visibility: visible;
            top: 52px;

          }
        }
      }
    }


  }

  li.active span {
      color: rgba(248, 248, 249, 1);
      font-weight: 500 !important;
  }

  //li.active-sub a {
  //  color: red !important;
  //}

  //search

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 1100px) {
    .desktop-menu__bottom ul li span {
      font-size: 13px;
    }
  }

`;

const StyledMobileMenu = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  overflow: visible !important;
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  //display: none;
  transition: all 0.5s ease-in-out;

  .dc-btn {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0px;
    right: 0px;
    bottom: 0;
  }


  .mobile-menu {
    .logo {
      display: flex;
      align-items: center;

      img {
        height: 35px !important;

        &:nth-last-of-type(1) {
          display: none;
        }
      }
    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;
      //justify-content: flex-end;

      span {
        position: absolute !important;
        right: 15px !important;
        //height: 25px !important;

        &:nth-of-type(2) {
          display: none !important;
        }
      }

      &.menu-open span {
        &:nth-of-type(1) {
          display: none !important;
        }

        &:nth-of-type(2) {
          display: block !important;
          right: 20px !important;
        }
      }
    }

    &__items {
      display: none;
      position: absolute;
      opacity: 0;
      background-color: #F8F8F9;
      height: 100vh;
      top: 70px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 55px 15px 60px 15px;
      //overflow: auto;
      transition: all 0.5s ease-in-out;
      overflow: auto;

      .mobile-menu__items__contact {
        height: 50px;
        width: 100%;
        border-radius: 24px;
        text-align: center;
        background-color: ${hover};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        margin-top: 10px;

        p {
          a {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            line-height: 24px;
          }
        }


      }

      &__top {
        ul {
          li {
            a {
              height: 35px;
              border-radius: 18px;
              background-color: rgba(136, 136, 136, 0.5);
              color: #ffffff;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 30px;

              img {
                margin-right: 10px !important;
              }
            }

            &:nth-last-child(1) a {
              margin-bottom: 60px;
              background-color: ${hover};

              &:hover {
                color: #ffffff !important;
              }
            }
          }
        }
      }

      &__ul {
        li {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(43, 57, 68, 0.2);
          margin-bottom: 20px;

          &:nth-last-child(1) {
            border-bottom: 0;
          }

          a {
            color: #2B3944;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
          }

          &.active {
            border-color: #CA0034;

            a {
              color: #CA0034;
            }
          }
        }
      }
    }

    &.menu-open {
      .mobile-menu__items {
        display: block;
      }
    }

    //accordion
    .accordion-item {
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid ${hover};
          color: ${hover};
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;

          &.collapsed {
            border-bottom: 1px solid rgba(43, 57, 68, 0.2)!important;
            color: #2B3944;
          }

          img {
            height: 20px;
            margin-top: 6px;

            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }

          &.collapsed {
            img {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }
          }

          &.collapsed {
            border-bottom: 1px solid #e1e4e6;
          }
        }

        &.active {
          .accordion-button {
            border-bottom: 1px solid rgb(25, 97, 172);
            color: rgb(25, 97, 172);
          }
        }
      }

      .accordion-body {
        padding-bottom: 25px;
        //padding-top: 20px;

        ul {
          li {
            border: none;
            padding: 0;
            margin: 0;
            font-size: 12px !important;
            line-height: 18px !important;


            a {
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              color: #9CABB9;
              padding: 20px 0;
              //margin-bottom: 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              display: block;

              svg {
                display: none;
              }

            }

            &:nth-of-type(1) a {
              padding-top: 0;
            }

            &:nth-last-of-type(1) a {
              border: none;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          &.collapsed {
            border: none;
          }

        }
      }
    }
  }

  @media (min-width: 993px) {
    display: none;
  }

  @media (max-width: 993px) {
    &:before {
      content: "";
      position: absolute;
      //background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(0 0 0) 68%);
      //background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 92%) 3%);
      height: 100%;
      width: 100%;
      left: 0px;
      top: 0px;
      background: rgba(0, 0, 0, 0.3);
    }
  }



`;
export default React.memo(MainMenu);
