import React, {useEffect} from "react";
import styled from "styled-components";
import Select, {components} from "react-select";
import {gsap} from "gsap";

const CustomDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.414"
            height="11.207"
            viewBox="0 0 11.414 11.207"
            style={{marginRight:"20px"}}
        >
            <g id="Arrow" transform="translate(0.707 10.5) rotate(-90)">
                <line
                    id="Line_123721"
                    data-name="Line 123721"
                    x1="5"
                    y2="5"
                    fill="none"
                    stroke="#f8f8f9"
                    stroke-linecap="round"
                    stroke-width="1"
                />
                <line
                    id="Line_123731"
                    data-name="Line 123731"
                    x1="5"
                    y1="5"
                    transform="translate(0 5)"
                    fill="none"
                    stroke="#f8f8f9"
                    stroke-linecap="round"
                    stroke-width="1"
                />
                <line
                    id="Line_11"
                    data-name="Line 11"
                    x2="10"
                    transform="translate(10 5) rotate(180)"
                    fill="none"
                    stroke="#f8f8f9"
                    stroke-linecap="round"
                    stroke-width="1"
                />
            </g>
        </svg>
    </components.DropdownIndicator>
);

const MyComponent = ({placeholder, options, radius, height, handleOnChange}) => {
    const customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#CA0034", // Background color
            borderRadius: radius ? radius : "20px",
            borderColor: "#CA0034",
            boxShadow: "unset",
            "&:hover": {
                borderColor: "transparent", // Remove the border color on hover
            },
            height: height ? height : "37px",
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#F8F8F9",
            borderRadius: "0px",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected
                ? "#CA0034"
                : state.isFocused
                    ? "#2B3944"
                    : "#F8F8F9", // Set the background color for selected and focused items
            color: state.isSelected || state.isFocused ? "white" : "inherit", // Change font color to white for selected and focused items
            paddingLeft: "35px", // Add 20px of padding to the left of option items
            transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out", // Add transition effect for background-color and color changes
            cursor: "pointer", // Change cursor to pointer on hover
            "&:hover": {
                backgroundColor: state.isSelected ? "#CA0034" : "#2B3944", // Adjust hover background color for better contrast
            },
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: "#F8F8F9", // Color for the selected value
            marginLeft: "30px",
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#F8F8F9", // Color for the placeholder text
            paddingLeft: "30px",
        }),
        indicatorSeparator: (baseStyles) => ({
            display: "none", // Hide the indicator separator
        }),
    };

    return (
        <StyledComponent className={"dropdownLocation"}>
            <Select
                components={{DropdownIndicator: CustomDropdownIndicator}}
                styles={customStyles}
                options={options}
                isSearchable={false}
                placeholder={placeholder}
                onChange={handleOnChange}
            />
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .css-3rlt5q-control:hover {
    border-color: #ca0034 !important;
  }

  .css-anhg02-control {
    box-shadow: unset !important;
  }

    .css-18rq6bb-control .css-1hb7zxy-IndicatorsContainer .css-151sz6c-indicatorContainer {
      padding: 0 !important;
  }
    .css-1xc3v61-indicatorContainer{
        //padding: 0 !important;
        
    }

  .react-select-3-lightbox {
    background-color: orange;
  }
`;

export default React.memo(MyComponent);
