import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
}

export const fetchUpsData = createAsyncThunk("fetchUpsData", (params) => {
    return get(params);
});

export const postForm = createAsyncThunk("UspForm", (params) => {
    return post(params);
});

const postSlice = createSlice({
    name: 'ups-detail',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchUpsData.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchUpsData.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchUpsData.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })

        builder.addCase(postForm.pending, (state) => {
            state.loading = true;
            state.success = [];
            state.error = "";
        });
        builder.addCase(postForm.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = "";

        });
        builder.addCase(postForm.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error;

        });

    }
})


export default postSlice.reducer
