import {Route, Switch, useLocation} from "react-router-dom";
import Error from "./pages/404";
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import React, {useRef} from "react";

// page imports
import About from "./pages/about";
import Components from "./pages/components";
import Home from "./pages/home";
import Project from "./pages/project";
import ProjectDetail from "./pages/project/single";
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useState} from "react";
import MediaCenterDetails from "./pages/media/single";
import MediaCenter from "./pages/media";
import Contact from "./pages/contact";
import Station from "./pages/station";
import MainMenu from "./components/MainMenu";
import Footer from "./components/Footer";
import Call from "./components/Call";
import Autogas from "./pages/autogas";
import Commercial from "./pages/residential";
import Industrial from "./pages/industrial";
import Ups from "./pages/usp";
import {ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchFooterData, fetchFooterPageData} from "./api/redux/footer";
import {ApiParam} from "./api/network/apiParams";
import WebFont from 'webfontloader';
import {AnimatePresence} from "framer-motion";

// icons
const messenger = (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.708" height="24" viewBox="0 0 23.708 24">
        <g id="Group_24380" data-name="Group 24380" transform="translate(11175 278)">
            <path id="Path_9615" data-name="Path 9615"
                  d="M154.974,142.232c-.148-.083-.316-.178-.518-.3-.113-.068-.255-.161-.4-.26a4.016,4.016,0,0,0-1.853-.865.906.906,0,0,0-.327.059,3.163,3.163,0,0,0-1.14,1.05,5.583,5.583,0,0,1-.419.488,8.229,8.229,0,0,1-4-4,5.563,5.563,0,0,1,.491-.421,3.168,3.168,0,0,0,1.047-1.136c.215-.558-.179-1.226-.806-2.181-.1-.15-.192-.292-.259-.4-.122-.2-.216-.371-.3-.519-.342-.607-.568-1.009-1.416-1.009a3.328,3.328,0,0,0-1.856,1.036,3.522,3.522,0,0,0-1.188,2.44c0,2.238,1.251,4.8,3.427,7.04l.011.008c2.237,2.176,4.8,3.421,7.041,3.421h0a3.516,3.516,0,0,0,2.439-1.183,3.315,3.315,0,0,0,1.037-1.85C155.982,142.8,155.581,142.574,154.974,142.232Zm-2.469,3.293c-1.906,0-4.232-1.157-6.222-3.09-1.934-1.99-3.089-4.316-3.091-6.222a3.108,3.108,0,0,1,1.887-2.309.916.916,0,0,1,.142.008,3.72,3.72,0,0,1,.246.4c.083.147.186.331.317.549.079.132.178.282.283.442a6.489,6.489,0,0,1,.664,1.169,4.512,4.512,0,0,1-.658.595c-.5.4-.939.75-.939,1.241,0,1.186,1.529,2.805,2,3.274s2.088,2,3.276,2c.488,0,.834-.435,1.234-.937a4.476,4.476,0,0,1,.6-.66,6.47,6.47,0,0,1,1.17.664c.16.1.31.2.443.284.218.131.4.234.548.317a3.727,3.727,0,0,1,.4.246.926.926,0,0,1,.008.142A3.114,3.114,0,0,1,152.5,145.525Z"
                  transform="translate(-11312.025 -405.735)" fill="#fff"/>
            <path id="Path_9616" data-name="Path 9616"
                  d="M78.026,64.031A12.18,12.18,0,0,0,65.91,52.069,11.467,11.467,0,0,0,54.418,62.651a12.056,12.056,0,0,0,1.862,7.426l-1.926,5.2a.584.584,0,0,0,.548.786.592.592,0,0,0,.228-.053l5.015-2.134A11.978,11.978,0,0,0,66.5,75.687h0A11.45,11.45,0,0,0,78.026,64.031Zm-4.185,7.5A10.306,10.306,0,0,1,66.5,74.548a10.835,10.835,0,0,1-5.975-1.8.584.584,0,0,0-.549-.05L55.919,74.42l1.559-4.214a.584.584,0,0,0-.064-.529,10.606,10.606,0,0,1,1.152-13.422,10.307,10.307,0,0,1,7.344-3.018A11.013,11.013,0,0,1,76.858,64.046,10.3,10.3,0,0,1,73.841,71.53Z"
                  transform="translate(-11229.318 -330.069)" fill="#fff"/>
        </g>
    </svg>
);

const call = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.68"
        height="18.97"
        viewBox="0 0 17.68 18.97"
    >
        <path
            id="Path_8986"
            data-name="Path 8986"
            d="M16.572,13.2c-1.146-.98-2.309-1.574-3.441-.6l-.676.592c-.495.429-1.414,2.436-4.969-1.654S6.047,6.826,6.542,6.4l.68-.592c1.126-.981.7-2.216-.111-3.487l-.49-.77C5.8.283,4.916-.55,3.787.429l-.61.533A5.726,5.726,0,0,0,.945,4.752c-.407,2.693.877,5.777,3.82,9.161S10.582,19,13.308,18.97a5.742,5.742,0,0,0,4.061-1.682l.612-.534c1.126-.979.426-1.975-.72-2.958Z"
            transform="translate(-0.872 0)"
            fill="#f8f8f9"
        />
    </svg>
);
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function App() {
    const store = useSelector(store => store)
    const [offset, setOffset] = useState("90");
    const location = useLocation();
    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL_SETTING;
        let api_url2 = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParam.TYPE]: "slug",
            [ApiParam.VALUE]: "footer",
            [ApiParam.GET_SECTION]: "yes",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.PAGE_ID]: "47",
        };

        dispatch(fetchFooterData([api_url]));
        dispatch(fetchFooterPageData([api_url2, api_params]));
    }, []);

    const getData = useSelector((state) => state.footer);
    const footerData = getData?.posts?.data;
    const footerBrand = getData?.detail?.data?.page_images?.list;

    //canonical Link
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);

    //global offset
    useEffect(() => {
        const updateOffset = () => {
            if (window.innerWidth > 767) {
                const container = document.querySelector(".container");
                if (container) {
                    setOffset(container.offsetLeft + 15);
                }
            }
        };

        // Add an event listener for the DOMContentLoaded event
        document.addEventListener("DOMContentLoaded", updateOffset);

        // Add event listener to update offset on window resize
        window.addEventListener("resize", updateOffset);
        window.addEventListener("load", updateOffset);

        return () => {
            document.removeEventListener("DOMContentLoaded", updateOffset);
            window.removeEventListener("resize", updateOffset);
            window.removeEventListener("load", updateOffset);
        };
    }, [offset]);

// route change scroll top & page class
    useEffect(() => {
        if (location.pathname === "/") {
            document.body.classList.add("in-home-page");
        } else {
            document.body.classList.remove("in-home-page");
        }

        window.scroll(0, 0);
    }, [location.pathname]);

    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: window.innerWidth > 767 ? 2 : '',
            effects: window.innerWidth > 767 ? true : ''
        });
        if (location.hash) {
            setTimeout(() => {
                const target = document.querySelector(location.hash);
                if (target) {
                    const yOffset = -110; // Adjust this value to change the offset
                    window.scrollTo({
                        top: target.getBoundingClientRect().top + window.pageYOffset + yOffset,
                        behavior: 'smooth'
                    });
                }
            }, 300);
        }
        return () => {
            smoother.kill();
        };
    }, [location.pathname, location.hash, store]);

    useEffect(() => {
        WebFont.load({
            custom: {
                families: `Suisse Int'l`,
                urls: ['/fonts/SuisseIntl-Light.woff',
                    '/fonts/SuisseIntl-Book.woff',
                    '/fonts/SuisseIntl-Semibold.woff',
                    '/fonts/SuisseIntl-Medium.woff',],
            },
        });
    }, [store]);

// animation functions
    ImageReveal();
    Parallax();
    ParallaxImg();
    SplitUp();

    return (
        <>
            <GlobalStyle/>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                closeOnClick
                hideProgressBar={true}
            />
            <MainMenu/>
            <div className="global-icon">
                <Call
                    shadow={"rgba(0, 0, 0, 1) 0px 5px 20px -5px"}
                    url={"tel:+8809610908070"}
                    height={40}
                    width={40}
                    icon={call}
                />
                <Call
                    shadow={"rgba(0, 0, 0, 1) 0px 5px 20px -5px"}
                    height={40}
                    width={40}
                    icon={messenger}
                    url={"https://wa.me/+8801708124188"}
                    target={'_blank'}
                />
            </div>
            {/*page transition*/}
            <div className="page-transition">
                <img className={"logo"} src="/images/static/logo.svg" alt=""/>
            </div>
            <div className="App" id="smooth-wrapper" ref={el}>
                <div id="smooth-content">
                    <AnimatePresence exitBeforeEnter>
                        <Switch location={location} key={location.pathname}>
                            <Route exact path="/">
                                <Home offset={offset}/>
                            </Route>
                            <Route exact path={`/media-center`} component={MediaCenter}/>
                            <Route
                                exact
                                path={`/media-center/:slug`}
                                component={MediaCenterDetails}
                            />
                            <Route exact path="/contact" component={Contact}/>
                            <Route exact path="/filling-stations" component={Station}/>
                            <Route exact path="/about">
                                <About offset={offset}/>
                            </Route>
                            <Route exact path="/autogas">
                                <Autogas offset={offset}/>
                            </Route>
                            <Route exact path="/residential">
                                <Commercial offset={offset}/>
                            </Route>
                            <Route exact path="/industrial">
                                <Industrial offset={offset}/>
                            </Route>
                            <Route exact path="/usp-detail">
                                <Ups offset={offset}/>
                            </Route>
                            <Route exact path="/components">
                                <Components offset={offset}/>
                            </Route>
                            <Route exact path={`/project`} component={Project}/>
                            <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                            <Route component={Error}/>
                        </Switch>
                    </AnimatePresence>
                    <Footer brands={footerBrand} data={footerData}/>
                </div>
            </div>
        </>
    );
}

export default App;
