import React, {useState} from 'react';
import styled from 'styled-components';
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css'
import Call from "./Call";
import {Img} from "./Img";
import {ParallaxBg} from "./ParallaxBg";
import SimpleBar from "simplebar-react";
import Modal from "react-bootstrap/Modal";
import {Container, Row} from "react-bootstrap";

const playIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
    <path id="Polygon_2" data-name="Polygon 2" d="M13.5,3.744a3,3,0,0,1,4.992,0L28.891,19.336A3,3,0,0,1,26.394,24H5.606a3,3,0,0,1-2.5-4.664Z" transform="translate(24) rotate(90)" fill="#f8f8f9"/>
</svg>


const VideoModal = ({video}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
    };
    return (

        <StyledVideo data-scroll-container
                     className={`video_body`}>
            {/*<ModalVideo*/}
            {/*    channel="youtube"*/}
            {/*    isOpen={open}*/}
            {/*    videoId={videoId}*/}
            {/*    onClose={handleCloseModal}*/}
            {/*    autoplay // Add the autoplay prop to enable video autoplay*/}
            {/*/>*/}

            <div onClick={() => handelOpen(true, video?.short_title ? video?.short_title : 'gRmsZkk1fo')} className="image_video_wrap">
                <Img  src={video?.full_path ? video?.full_path : '/images/dynamic/video-popup.jpg'} alt={video?.short_title} />
                <div className="play" data-speed={1.2}>
                    <Call icon={playIcon} height={80} width={80} />
                </div>
            </div>

            <Modal
                show={open}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className=" modal_video_popup"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledVideo>

    )
};


const StyledVideo = styled.div`
  .container {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : ''};
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      .col-md-12 {
        padding: 0;
      }
    }
  }
  

  .image_video_wrap {
    padding-top: calc(620 / 668 * 100%);
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      @media (max-width: 767px) {
        height: 50px;
        width: 50px;
        svg {
          height: 24px;
          width: 32px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        border: none;
        background: rgba(86, 84, 64, 0.5);
        border-radius: 50%;
        opacity: 0;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
        z-index: -4;
      }

      &:after {
        content: "";
        z-index: 0;
        //background-color: rgba(30, 92, 149, 0.5);
        overflow: hidden;
        border-radius: 50%;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
      }

      svg {
        #Ellipse_396, path {
          transition: 0.7s all ease;
        }
      }
    }

    .global-image {
      img {
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.01);
      }
    }

    @media (max-width: 767px) {
      padding-top: calc(350 / 375 * 100%);
    }

    &:hover {
      .global-image {
        img {
          transform: scale(1.04);

        }

      }

      .play {
        &:before {
          animation: cubic-bezier(0.4, 0, 0, 1) 1;
          animation-duration: 0.9s;
          animation-direction: alternate-reverse;
          animation-iteration-count: infinite;
        }
        .navigation li.hover::after {
          top: 0px;
        }

        &:after {
          opacity: 0;
        }

        svg {
          #Ellipse_396 {
            r: 50;
          }

          path {
            fill: white;
          }
        }

      }
    }

  }

  .modal-video-movie-wrap {
    bottom: -170px;
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {

    .modal-video-movie-wrap {
      bottom: -470px;
    }
  }

`;


export default React.memo(VideoModal);














