import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import SingleBlog from "../SingleBlog";
import Select from "react-select";
import Gallery from "../Gallery";

const MyComponent = ({
                         data,
                         category
                     }) => {
    const [activeItem, setActiveItem] = useState("All");
    const [selectType, setSelectType] = useState("All");
    const [filteredData, setFilteredData] = useState(data); // Initialize with all data
    const selectTypeInputRef = useRef();

    // Handle initial selection when the component mounts
    useEffect(() => {
        handleItemClick("All");
    }, []);
    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `Black` : "#221F1F",
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : "#fff",
            margin: 0,
            fontSize: 14,
            cursor: "pointer",
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `black`,
                color: "#FFF",
                cursor: "pointer",
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#FFF",
            backgroundColor: state.isSelected ? `black` : "rgba(255,255,255)",
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            zIndex: 10,
            // width: 200,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#000" : "#FFF",
            borderRadius: 0,
            fontWeight: "400",
            color: "#FFF",
            fontSize: 14,
        }),
    };

    const typeas = [
        {value: "All", label: "All"},
        ...(Array.isArray(category)
            ? category.map((item) => ({
                value: item?.title,
                label: item?.title,
            }))
            : []),
    ];

    const filterData = (filter) => {
        if (filter === "All") {
            setFilteredData(data); // Show all data
        } else {
            // Filter the data based on the selected category title
            setFilteredData(data.filter((item) => item?.page_data?.type === filter));
        }
    };

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <StyledFilter>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={"news-filter"}>
                                {windowWidth > 767 ? (
                                    <ul>
                                        {typeas.map((item, i) => (
                                            <li
                                                key={i}
                                                className={`${
                                                    activeItem === item.value ||
                                                    (activeItem === "All" && item.value === "All")
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() => handleItemClick(item.value)}
                                            >
                                                {item.label}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Form className={"form"}>
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicPhone">
                                                <Select
                                                    classNamePrefix="filter"
                                                    isSearchable={false}
                                                    ref={selectTypeInputRef}
                                                    options={typeas}
                                                    onChange={(e) => handleType(e?.value)}
                                                    placeholder="Type"
                                                    styles={customStyles}
                                                    // components={{DropdownIndicator}}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Form>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledFilter>
            <StyledComponent>
                <Container className={"pt-160 pb-160"}>
                    <Row>
                        {filteredData &&
                            filteredData?.length > 0 &&
                            filteredData?.map((item, index) => {
                                return (
                                    <Col key={index} sm={4}>
                                        {
                                            item?.page_data?.type !== 'Gallery' ?
                                                <SingleBlog
                                                    date={
                                                        item?.page_data?.short_desc
                                                            ? item?.page_data?.short_desc
                                                            : ""
                                                    }
                                                    slug={item?.page_data?.slug}
                                                    category={item?.page_data?.type}
                                                    title={item?.page_data?.subtitle}
                                                    img={item?.images?.list?.[0]?.full_path}
                                                /> :
                                                <Gallery images={item?.images?.list}
                                                         thumbnail={item?.images?.list?.[0]?.full_path}
                                                         title={item?.page_data?.subtitle}
                                                         category={item?.page_data?.type}
                                                         date={
                                                             item?.page_data?.short_desc
                                                                 ? item?.page_data?.short_desc
                                                                 : ""
                                                         }
                                                />
                                        }
                                    </Col>
                                );
                            })}
                    </Row>
                </Container>
            </StyledComponent>
        </>
    );
};

const StyledComponent = styled.section`
    .col-sm-4 {
        padding-bottom: 30px;
    }

    .category {
        display: block;
    }

    .news-filter ul li {
        cursor: pointer !important;
    }

    .dropdownLocation {
        display: none;
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        .category {
            display: none !important;
        }

        .dropdownLocation {
            display: block;
        }
    }
`;

const StyledFilter = styled.section`
    background-color: #ca0034;
    padding-bottom: 20px;
    padding-top: 20px;

    .news-filter {
        ul {
            display: flex;
            position: relative;

            li {
                position: relative;
                padding-right: 60px;
                font-size: 20px;
                line-height: 28px;
                font-weight: 350;
                cursor: pointer !important;
                color: rgb(248 248 249 / 50%);

                &.active {
                    /* Add a border and adjust the position */
                    border-bottom: none; /* Remove the default border */
                    color: #f8f8f9;
                }
            }
        }

        form {
            padding: 0 !important;

            .form-group {
                width: 100% !important;
                margin-bottom: 0 !important;
            }
        }

        .filter__control {
            margin-bottom: 0 !important;

            &:focus {
                background-color: white !important;
                outline: none;
                box-shadow: none;
            }

            &:focus-within {
                background-color: white !important;
                outline: none;
                box-shadow: none;
            }
        }

        .filter__placeholder {
            color: #CA0034 !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-transform: capitalize;
            padding-left: 5px;

            &::selection {
                background: transparent !important;
            }
        }

        .filter__input {
            input {
                color: rgba(255, 255, 255, 0.6) !important;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }
        }

        .filter__single-value {
            color: #ca0034 !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            opacity: 0.6;
            text-transform: capitalize;
            text-indent: 20px;
        }

        .filter__indicator-separator {
            opacity: 0;
        }

        .filter__indicator {
            margin-right: 20px;
        }
    }
`;
export default React.memo(MyComponent);
