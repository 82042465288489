import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Locations from "../../components/station/Locations";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchStationData } from "../../api/redux/station";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/PageAnimation";

const Station = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const dispath = useDispatch();

  // api call
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let api_url = apiEndPoints.LOCATION;

        let api_params = {
          [ApiParam.DIVISION]: selectedDivision,
          [ApiParam.DISTRICT]: selectedDistrict,
        };
        await dispath(fetchStationData([api_url,api_params]));
        setIsLoading(false);
        // history.push(`/filling-stations${queryParams}`);
      } catch (err) {
        // Handle errors if needed
        console.error("Error fetching products:", err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedDivision, selectedDistrict]);

  const getData = useSelector((state) => state.station);

  const allDealers = getData?.posts?.data?.data;
  const divisions = getData?.posts?.divisions;
  const districtsOption = getData?.posts?.district;
  const innerBanner = getData?.posts?.page_data;
  const innerImage = getData?.posts?.page_data?.images?.list?.[0]?.full_path;

  const division = divisions?.map((item) => ({
    label: item?.data,
    value: item?.data,
  }));

  const districts = districtsOption?.map((item) => ({
    label: item?.data,
    value: item?.data,
  }));

  // handle selected division and district
  const handleDivisionChange = (selectedOption) => {
    setSelectedDivision(selectedOption.value);
    setSelectedDistrict("");
  };

  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption.value);
  };

  return (
    <HelmetProvider>
      <motion.div
        key={`581`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Omera Gas One | Filling Stations</title>
        <meta name="Find Your Nearest Station" content="Find your nearest Omera Gas One filling station effortlessly with our user-friendly locator tool. Convenience at your fingertips" />
      </Helmet>

      <StyledComponent>
        {isLoading && (
          <div className={"loader"}>
            <span className={"spinner"}></span>
          </div>
        )}
        <InnerBanner
          subtitle={innerBanner?.page_data?.description}
          breadcrumbs={innerBanner?.page_data?.subtitle}
          title={innerBanner?.page_data?.short_desc}
          img={innerImage}
        />
        <Locations
          onDivisionChange={handleDivisionChange}
          onDistrictChange={handleDistrictChange}
          districts={districts}
          division={division}
          data={allDealers}
        />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  /* small mobile :320px. */
  position: relative;
`;

export default memo(Station);
