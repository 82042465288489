import React from "react";
import styled from "styled-components";
import { Img } from "./Img";
import { Link } from "react-router-dom";

const SingleBlog = ({ img, date, category, title, slug }) => {
  const dateObject = new Date(date);

  const getMonth = dateObject.toLocaleString("default", { month: "long" });
  const getYear = dateObject.getFullYear();
  const monthAndYear = `${getMonth} ${getYear}`;
  // console.log('date', date)

  return (
    <StyledComponent>
      <Link to={`/media-center/${slug}`}>
        <div className={"blog"}>
          <div className="blog__single">
            <div className="blog__single__img">
              <Img src={img ? img : "/images/dynamic/blog/blog-01.jpg"} />
            </div>
            <div className="blog__single__content">
              {date && <h6 className={"meta-font"}>{date}</h6>}

              <h5 className={""}>{category}</h5>
              <h6>{title}</h6>
            </div>
          </div>
        </div>
      </Link>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  height: 100%;
  .blog {
    height: 100%;
    &__single {
      border: 1px solid rgba(25, 27, 29, 0.1);
      overflow: hidden;
      height: 100%;
      &__img {
        position: relative;
        padding-top: calc(200 / 370 * 100%);
        overflow: hidden;
        img {
          transition:  all 1.3s ease 0s; /* Adding a smooth transition effect */
          transform: scale(1.01);
        }
      }

      &:hover {
        .blog__single__img {
          img {
            transform: scale(1.1); /* Increase the scale on hover */
          }
        }
        .blog__single__content {
          //h6 {
          //  color: rgb(202, 0, 52);
          //}
        }
      }

      &__content {
        padding: 40px 37px 100px 40px;

        .meta-font {
          color: #83909d;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
        }

        h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #ca0034;
          margin-bottom: 20px;
        }

        h6 {
          color: #2b3944;
          font-weight: 500;
          display: flex;
          flex-wrap: wrap;
          letter-spacing: unset;
        }
      }
    }
  }

  //  Responsive Mobile Device
  /* small mobile :320px. */
  @media (max-width: 767px) {
    .blog {
      &__single {
        &__img {
          position: relative;
          padding-top: calc(186 / 345 * 100%);
        }

        &__content {
          padding: 30px 30px 94px 30px;
        }
      }
    }
  }
`;

export default React.memo(SingleBlog);
