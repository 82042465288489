import React from 'react';
import styled from 'styled-components';
import {title} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Subtitle = ({
                      text,
                      fontSize,
                      fontWeight,
                      color,
                      letterSpacing,
                      lineHeight,
                      textTransform,
                      margin,
                      padding,
                      borderColor,
                      varient,
                      center,
                      classname,
                      fontStyleChange
                  }) => {
    return (
        <StyledTitle className={`title fade-up ${classname}`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     padding={padding}
                     varient={varient}
                     center={center}
                     borderColor={borderColor}
                     fontStyleChange={fontStyleChange}
                     >

            <h3 className={'split-up'}>{ReactHtmlParser(text)} </h3>
        </StyledTitle>

    )
};


const StyledTitle = styled.div`
    margin: ${props => props.margin || '0px'};
    position: relative;
    width: 100%;
    font-family: ${title};
    text-align: ${props => props?.center ? 'center' : ''};
    padding: ${p => p.padding};

    h3 {
        text-transform: capitalize;
        font-family: ${props => props.fontStyleChange && '"Noto Sans Symbols", sans-serif'};
        font-weight: ${props => props.fontStyleChange && 600};
    }

    span {
        color: ${props => props.spanColor ? props.spanColor : 'rgb(202, 0, 52)'};
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        font-weight: ${props => props.fontStyleChange && 600};
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        span {
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
        }
    }
`;


export default React.memo(Subtitle);














