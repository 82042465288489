import React, {memo, useEffect} from "react";
import styled from "styled-components";
import { HelmetProvider, Helmet } from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import MissionVision from "../../components/MissionVission";
import TextWithLiner from "../../components/TextWithLiner";
import Peoples from "../../components/Peoples";
import Awards from "../../components/Awards";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { ApiParam } from "../../api/network/apiParams";
import { fetchAboutData } from "../../api/redux/about";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {useLocation} from "react-router-dom";


const MyComponent = ({ offset }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)
  const dispath = useDispatch();
  const location=useLocation();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;

    let api_params = {
      [ApiParam.TYPE]: "slug",
      [ApiParam.VALUE]: "about",
      [ApiParam.GET_SECTION]: "yes",
      [ApiParam.IMAGE]: "yes",
      [ApiParam.POST]: "yes",
    };

    dispath(fetchAboutData([api_url, api_params]));
  }, [PageAnimation]);

  const getData = useSelector((state) => state.about);
  const innerBanner = getData?.posts?.data;
  const overview = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "overview"
  )?.section_data;
  const missionVision = getData?.posts?.data?.sections;
  const quote = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "quote"
  )?.section_data;
  const corporatePhilosophy = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "corporate-philosophy"
  )?.section_data;
  const chairman = getData?.posts?.data?.sections?.find(
    (f) => f?.section_data?.slug === "chairmans-message"
  )?.section_data;
  const chairmanImage =
    getData?.posts?.data?.sections?.[3]?.images?.list?.[0]?.full_path;

  const management = getData?.posts?.data?.sections?.[4];

  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  useEffect(() => {
    if (location.hash && !getData?.loading) {
      const targetElement = document.querySelector(location.hash);
      const topPx = targetElement?.offsetTop;
      console.log(topPx);
      if (targetElement) {
        gsap.to(window, {
          duration: 0.8,
          scrollTo: topPx
        });
      }
    }
  }, [location, getData]);

  return (
    <HelmetProvider>
      <motion.div exit="exit" animate="anim" initial="init" key={'181'} className="page-loader pagetransition-loader"
                  variants={PageAnimation}
      >
      </motion.div>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Omera Gas One | About</title>
        <meta
            name="Omera Gas One Ltd. stands at the forefront of Bangladesh's energy sector, marking a significant milestone in the nation's quest for sustainable and efficient energy solutions.
"
          content="Omera Gas One Ltd. stands as a testament to innovation, partnership, and unwavering commitment in the energy sector of Bangladesh. Founded in 2016, we proudly represent the first-ever Bangladesh-Japan joint venture company dedicated to providing exceptional Liquefied Petroleum Gas (LPG) solutions. Our inception marked a turning point in Bangladesh's energy landscape, as we brought together the expertise of Omera Petroleum Ltd. and Saisan Co., Ltd. in a harmonious synergy.
"
        />
      </Helmet>

      <StyledComponent>
        <InnerBanner
            subtitle={innerBanner?.page_data?.description}
            breadcrumbs={
              innerBanner?.page_data?.breadcumb
                  ? innerBanner?.page_data?.breadcumb
                  : ""
            }
            title={
              innerBanner?.page_data?.short_desc
                  ? innerBanner?.page_data?.short_desc
                  : "The imperial <br> legacy"
            }
            img={
              innerBanner?.page_images?.list?.[0]?.full_path
                  ? innerBanner?.page_images?.list?.[0]?.full_path
                  : "images/static/blur.jpg"
            }
        />
        <Overview
            description={overview?.description}
            title={overview?.short_desc}
        />
        <MissionVision data={missionVision} offset={offset}/>
        <TextWithLiner data={quote}/>
        <div className="show-in-desktop">
          <Peoples id={"management-team"} data={management} offset={offset}/>
        </div>
        <div className="show-in-laptop">
          <Awards data={management} offset={offset}/>
        </div>
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  .show-in-laptop {
    display: none;
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .show-in-desktop {
      display: none;
    }
    
    .show-in-laptop {
      display: block;
    }
  }
  
  /* small mobile :320px. */
  @media (max-width: 767px) {
    .show-in-desktop {
      display: none;
    }
    .show-in-laptop {
      display: block;
    }
  }
`;

export default memo(MyComponent);
