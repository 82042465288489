import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Subtitle from "../Subtitle";
import ServiceItem from "../ServiceItem";

const MyComponent = ({data, dataContent}) => {
    return (
        <StyledComponent className={'pt-160 pb-160'}>
            <div className="services-area">
                <Container>
                    <Row>
                        <Col sm={6}>
                            <div className="services-area__left">
                                {data?.section_data?.subtitle && <Subtitle
                                    fontStyleChange
                                    text={data?.section_data?.subtitle ? data?.section_data?.subtitle : ''}/>}
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="services-area__right">
                                <h6>{data?.section_data?.description}</h6>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'services-area__wrap'}>
                        {
                            dataContent?.map((item, index) => {
                                return (
                                    <Col key={index} lg={4} md={12}>
                                        <ServiceItem url={item?.page_data?.slug}
                                                     name={item?.page_data?.title ? item?.page_data?.title : 'Autogas'}
                                                     desc={item?.page_data?.short_desc}
                                                     img={item?.images?.list?.find((f) => f.thumb === "on")?.full_path}
                                                     number={index + 1}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    h3 {
        line-height: 60px;
    }

    .services-area {
        &__right {
            overflow: hidden;

            h6 {
                overflow: hidden;
            }
        }

        &__wrap {
            padding-top: 60px;
            /* small mobile :320px. */
            @media (max-width: 767px) {
                padding-top: 40px;
            }
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        .services-area {
            &__left {
                padding-bottom: 40px;
            }

            &__wrap {
                display: flex;
                gap: 30px;
            }
        }

    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
        .services-area {
            &__left {
                padding-bottom: 40px;
            }

            &__wrap {
                display: flex;
                gap 30px;
            }
        }
    }
`;

export default React.memo(MyComponent);
