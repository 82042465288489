import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Img} from "./Img";
import PeoplePopup from "./PeoplePopup";
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import Call from "./Call";
import reactHtmlParser from "react-html-parser";
import {RemoveScroll} from "react-remove-scroll";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const ConcernBox = ({img, title, link, des, logo, md, data, description}) => {

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = (e) => {
        setShow(false);
        document.body.classList.remove('no-scroll')
    }

    const handleShow = (e) => {
        setShow(true);
        setPopupId(e);
        document.body.classList.add('no-scroll')

    }



    return (

        <StyledConcernBox className={'team-box'} md={md}>
            <div className="wrapper"  >
                <div className={'pointer '} onClick={() => handleShow(data)}>
                    <div className={'img-wrapper '}>
                        <Img src={img ? img : '/images/dynamic/chairman.jpg' }/>
                    </div>
                    <div className="information">
                        <p className={'split-up'} >{title ? title : 'Isabella Mireau'}</p>
                        <p className={'split-up'}>{des ? des : 'CTO'}</p>
                    </div>
                </div>

            </div>
            <div id="testing">
                <PeoplePopup description={description} img={img} des={des} title={title} show={show} data={popupId} handleClose={handleClose}/>

            </div>


        </StyledConcernBox>

    )
};


const StyledConcernBox = styled.div`
  .wrapper {
    position: relative;

    .img-wrapper {
      padding-top: calc(330 / 270 * 100%);
      position: relative;
      overflow: hidden;

      .global-image img {
        transform: ${props => props.md ? 'scale(1)' : 'scale(1.01)'};
        transition: all 0.3s ease-in;
      }

    }

    .information {
      margin-top: 20px;
      text-align: ${props => props.md ? 'left' : 'center'};

      p {
        margin: 0 0 3px;

        &:last-child {
          color: #7E7576;
          margin: 0;
        }
      }
    }

    a {
      &:hover {
        .img-wrapper {
          .global-image img {
            transform: ${props => props.md ? 'scale(1)' : 'scale(1.015)'};
          }
        }
      }
    }
  }
  .reveal{
    overflow: hidden;
  }
`;

export default React.memo(ConcernBox);














