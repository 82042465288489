import React, {memo, useEffect} from 'react';
import styled from "styled-components";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import OverviewList from "../../components/OverviewList";
import Safety from "../../components/Safety";
import Plan from "../../components/Plan";
import GetInTouch from "../../components/home/GetInTouch";
import Projects from "../../components/services/Projects";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchServiceData} from "../../api/redux/services";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";

const MyComponent = ({offset}) => {
    const dispath = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CHILD_PAGE;

        let api_params = {
            [ApiParam.PAGE_ID]: "10",
            [ApiParam.IMAGE]: "yes",
            [ApiParam.POST]: "yes",
            [ApiParam.FILE]: "yes",
            [ApiParam.GALLERY]: "yes",
        };

        dispath(fetchServiceData([api_url, api_params]));
    }, [PageAnimation]);

    const getData = useSelector((state) => state.services);
    const autogas = getData?.posts?.data?.list?.find((f) => f?.page_data?.slug === "industrial")
    const overview = autogas?.posts?.list.find((f) => f?.data?.slug === "overview");
    const serviceList = autogas?.posts?.list.find((f) => f?.data?.slug === "service-list");
    const safetyGuidance = autogas?.posts?.list.find((f) => f?.data?.slug === "safety-guidance");
    const projectProcedure = autogas?.posts?.list.find((f) => f?.data?.slug === "project-procedure");
    const cta = autogas?.posts?.list.find((f) => f?.data?.slug === "cta");
    const projects = autogas?.posts?.list.find((f) => f?.data?.slug === "projects");
    const desktop = cta?.images.find((f) => f.desktop === "on")
    const mobile = cta?.images.find((f) => f.mobile === "on")

    return (<StyledComponent>
        <HelmetProvider>
            <motion.div key={`588`} className="page-loader" exit="exit" animate="anim"
                        variants={PageAnimation}
                        initial="init">
            </motion.div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Omera Gas One | Industrial</title>
                <meta name="Optimizing Operations with Omera Gas One Ltd."
                      content="Omera Gas One Ltd. offers a comprehensive insight into industrial LPG solutions, focusing on custom reticulated system installations utilizing cylinder banks and bulk storage tanks. Omera Gas One Limited delves into the efficiency, reliability, and cost-effectiveness of tailored LPG infrastructure for industrial applications. Discover how Omera Gas One Limited is revolutionizing the industrial LPG landscape with innovative solutions designed to meet diverse operational needs, from streamlined logistics to enhanced safety measures.
"/>
            </Helmet>
            <InnerBanner
                subtitle={autogas?.page_data?.description}
                breadcrumbs={autogas?.page_data?.subtitle}
                title={autogas?.page_data?.short_desc}
                img={autogas?.images?.list?.find((f) => f.desktop === "on")?.full_path}/>
            <Overview
                description={overview?.data?.description}
                title={overview?.data?.subtitle}/>
            <OverviewList data={serviceList} offset={offset}/>
            <Safety data={safetyGuidance}/>
            <Plan data={projectProcedure} offset={offset}/>
            <GetInTouch dataSpeed={0.8} pb={'unset'} buttonText={'Find Filling Stations Near You'} bgColor={'#2B3944;'}
                        redirect={'/filling-stations'}
                        title={cta?.data?.subtitle ? cta?.data?.subtitle : 'Convenience at your fingertips'}
                        bigImg={desktop?.full_path ? desktop?.full_path : '/images/dynamic/service-in-touch.jpg'}
                        mobileImg={mobile?.full_path ? mobile?.full_path : '/images/dynamic/service-in-touch-mb.jpg'}/>
            {projects && <Projects data={projects}/>}
        </HelmetProvider>
    </StyledComponent>);
};

const StyledComponent = styled.div`

`;

export default memo(MyComponent);
